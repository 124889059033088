<template>
    <div class="asd-slideshow">
        <div class="asd-vueper">
            <vueper-slides
                    speed="4000"
                    :fixed-height="baseH"
                    :breakpoints="breakpoints"
                    @before-slide="beforeSlideEvent"
                    @slide="slideEvent"
                    @ready="readyEvent">
                <vueper-slide
                        v-for="(slide, i) in slides"
                        :key="i"
                        :image="slide.image"></vueper-slide>
            </vueper-slides>
        </div>
        <div class="side-details" v-if="actual !== null">
            <h4 v-html="actual.title !== '' ? actual.title : 'no title'"></h4>
            <div v-html="actual.content !== '' ? actual.content : 'no description'"></div>
            <p class="readmore" v-if="readmore">
                <a :href="actual.link" :title="actual.title">Leggi tutto</a>
            </p>
        </div>
    </div>

</template>
<script>

    import Vue from "vue";

    export default {

        name: 'AsdSlideshow',

        data () {
            return {
                parallax: 1,
                actual: null,
                sliding: true,
            }
        },

        props: {
            slides: {
                type: Array,
                required: true,
                default: null,
            },

            baseH: {
                type: String,
                required: false,
                default: '50vh',
            },

            breakpoints: {
                type: Object,
                required: false,
                default: {}
            },
            readmore: {
                tpye: Boolean,
                required: false,
                default: true
            }
        },

        methods: {

            beforeSlideEvent( event, params) {
                //console.log(event, params);
                Vue.set(this, 'actual', this.slides[params.nextSlide.index]);
            },

            slideEvent( event, params) {
                //console.log(event, params);
                if (window.Asd.Waves) {
                    window.setTimeout( () => window.Asd.Waves.adjustWaves() , 100 );
                }
            },

            readyEvent( event, params ) {
                Vue.set(this, 'actual', this.slides[params.currentSlide.index]);

                window.setTimeout( () => window.Asd.Waves.adjustWaves() , 1000 );
            }
        }

    }
</script>
