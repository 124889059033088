import Slideout from 'slideout';

import decouple from 'decouple';


export default class AsdSlideout extends Slideout {

    constructor(options) {
        super(options);
        this.superConstructor();
    }

    superConstructor() {
        this._initTouchEvents();
    }

    static hasIgnoredElements(el) {
        while (el.parentNode) {
            if (el.getAttribute('data-slideout-ignore') !== null) {
                return el;
            }
            el = el.parentNode;
        }
        return null;
    }

    _initTouchEvents () {
        let self = this;

        /**
         * Those line should go into the constructor. I'm unable to put them there.
         * @type {Document}
         */
        this.document = window.document;
        this.html = this.document.documentElement;
        this.scrolling = false;
        this.scrollTimeout = null;
        this.touch = {
            'start': window.navigator.msPointerEnabled ? 'MSPointerDown' : 'touchstart',
            'move': window.navigator.msPointerEnabled ? 'MSPointerMove' : 'touchmove',
            'end': window.navigator.msPointerEnabled ? 'MSPointerUp' : 'touchend'
        };
        this.prefix = (function prefix() {
            var regex = /^(Webkit|Khtml|Moz|ms|O)(?=[A-Z])/;
            var styleDeclaration = window.document.getElementsByTagName('script')[0].style;
            for (var prop in styleDeclaration) {
                if (regex.test(prop)) {
                    return '-' + prop.match(regex)[0].toLowerCase() + '-';
                }
            }
            // Nothing found so far? Webkit does not enumerate over the CSS properties of the style object.
            // However (prop in style) returns the correct value, so we'll have to test for
            // the precence of a specific property
            if ('WebkitOpacity' in styleDeclaration) { return '-webkit-'; }
            if ('KhtmlOpacity' in styleDeclaration) { return '-khtml-'; }
            return '';
        }());

        /**
         * Decouple scroll event
         */
        this._onScrollFn = decouple( this.document, 'scroll', function() {
            if (!self._moved) {
                clearTimeout(this.scrollTimeout);
                this.scrolling = true;
                this.scrollTimeout = setTimeout(function() {
                    self.scrolling = false;
                }, 250);
            }
        });

        /**
         * Prevents touchmove event if slideout is moving
         */
        this._preventMove = function(eve) {
            if (self._moved && eve.cancelable) {
                eve.preventDefault();
            }
        };

        this.document.addEventListener( this.touch.move, this._preventMove, {passive: false, cancelable: true});

        /**
         * Resets values on touchstart
         */
        this._resetTouchFn = function(eve) {
            if (typeof eve.touches === 'undefined') {
                return;
            }

            self._moved = false;
            self._opening = false;
            self._startOffsetX = eve.touches[0].pageX;
            self._preventOpen = (!self._touch || (!self.isOpen() && self.menu.clientWidth !== 0));
        };

        this.panel.addEventListener(this.touch.start, this._resetTouchFn, {passive: true});

        /**
         * Resets values on touchcancel
         */
        this._onTouchCancelFn = function() {
            self._moved = false;
            self._opening = false;
        };

        this.panel.addEventListener('touchcancel', this._onTouchCancelFn);

        /**
         * Toggles slideout on touchend
         */
        this._onTouchEndFn = function() {
            if (self._moved) {
                self.emit('translateend');
                (self._opening && Math.abs(self._currentOffsetX) > self._tolerance) ? self.open() : self.close();
            }
            self._moved = false;
        };

        this.panel.addEventListener(this.touch.end, this._onTouchEndFn);

        /**
         * Translates panel on touchmove
         */
        this._onTouchMoveFn = function(eve) {
            if (
                this.scrolling ||
                self._preventOpen ||
                typeof eve.touches === 'undefined' ||
                AsdSlideout.hasIgnoredElements(eve.target)
            ) {
                return;
            }

            let dif_x = eve.touches[0].clientX - self._startOffsetX;
            let translateX = self._currentOffsetX = dif_x;

            if (Math.abs(translateX) > self._padding) {
                return;
            }

            if (Math.abs(dif_x) > 20) {

                self._opening = true;

                var oriented_dif_x = dif_x * self._orientation;

                if (self._opened && oriented_dif_x > 0 || !self._opened && oriented_dif_x < 0) {
                    return;
                }

                if (!self._moved) {
                    self.emit('translatestart');
                }

                if (oriented_dif_x <= 0) {
                    translateX = dif_x + self._padding * self._orientation;
                    self._opening = false;
                }

                if (!(self._moved && self.html.classList.contains('slideout-open'))) {
                    self.html.classList.add('slideout-open');
                }

                self.panel.style[self.prefix + 'transform'] = self.panel.style.transform = 'translateX(' + translateX + 'px)';
                self.emit('translate', translateX);
                self._moved = true;
            }

        };

        this.panel.addEventListener(this.touch.move, this._onTouchMoveFn, {passive: true} );

        return this;
    }
}
