/**
 * Main file
 *
 * @author Francesco Costa
 */

import doc_ready from 'document-ready';

import Scroller from '@html/asd/src/js/util/scroller.js'
import Navbar from '@html/asd/src/js/components/navbar.js'

import Capabilities from "@html/asd/src/js/util/Capabilities.js";

import axios from 'axios';


import Vue from "vue";
Vue.config.productionTip = false;
Vue.config.devtools = false;

// article modal box on slideshow in mod_articles_category layout vue-article
//import AsdModal from '@html/asd/src/js/components/AsdModal/AsdModal.js';

import Slideout from './components/AsdSlideout.js';

import AsdWaves from './components/AsdWaves.js';

import createAsdContact from '@joomla/mod_asd_contact/src/modules/mod_asd_contact/asset/index.js';

import AsdSlideshow from "./components/AsdSlideshow";

function createAsd() {

    let debug = 0;
    if ('debug' in window) debug = window.debug;

    window.Asd = {
        Scroller: null,
        Navbar: null,
        Modal: null,
        debug: debug
    };

    window.axios = axios;

    if (window.Asd.debug > 2) {
        console.log('Hello hello! AsdWebpack is here!');
    }

    /**
     * When all is ok (but resource can be not loaded yet)
     */
    doc_ready( function() {



        if (window.Asd.debug > 2) {
            console.log('Document is ready here in webpack!');
        }

        window.Asd.Scroller = new Scroller(window, document);

        window.Asd.Navbar = new Navbar( window.Asd.Scroller );

        window.Asd.Capatibilies = new Capabilities( window.Asd.Scroller );

        //alert('tolerance 0.9, W:'+ window.Asd.Capatibilies.windowX);

        Asd.Slideout = new Slideout({
            'panel': document.querySelector('body > .wrapper'),
            'menu': document.getElementById('menu'),
            'padding': 220,
            'tolerance': 200
        });

        let el = document.querySelector('.asd_menu_icon');
        el.addEventListener('click', function(e) {
            window.Asd.Slideout.toggle();
        });



    });

    let initWave = function() {
        console.log('loaded',new Date());
        window.Asd.Waves = new AsdWaves();
    };

    window.Asd.loadedContact = false;
    let onDocumentLoad = function () {
        initWave();

        if (!window.Asd.loadedContact) {
            createAsdContact();
            window.Asd.loadedContact = true;
        }

        AsdSlideshow.init();

    };

    window.addEventListener('load', onDocumentLoad);

}

export default createAsd;
