var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "asd-slideshow" }, [
    _c(
      "div",
      { staticClass: "asd-vueper" },
      [
        _c(
          "vueper-slides",
          {
            attrs: {
              speed: "4000",
              "fixed-height": _vm.baseH,
              breakpoints: _vm.breakpoints,
            },
            on: {
              "before-slide": _vm.beforeSlideEvent,
              slide: _vm.slideEvent,
              ready: _vm.readyEvent,
            },
          },
          _vm._l(_vm.slides, function (slide, i) {
            return _c("vueper-slide", { key: i, attrs: { image: slide.image } })
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.actual !== null
      ? _c("div", { staticClass: "side-details" }, [
          _c("h4", {
            domProps: {
              innerHTML: _vm._s(
                _vm.actual.title !== "" ? _vm.actual.title : "no title"
              ),
            },
          }),
          _vm._v(" "),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.actual.content !== ""
                  ? _vm.actual.content
                  : "no description"
              ),
            },
          }),
          _vm._v(" "),
          _vm.readmore
            ? _c("p", { staticClass: "readmore" }, [
                _c(
                  "a",
                  { attrs: { href: _vm.actual.link, title: _vm.actual.title } },
                  [_vm._v("Leggi tutto")]
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }