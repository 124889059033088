import Vue from "vue";
import AsdSlideshowVue from './AsdSlideshow.vue';
import {VueperSlide, VueperSlides} from "vueperslides";

export default class AsdSlideshow {

    static init() {

        Vue.component('VueperSlides',VueperSlides);
        Vue.component('VueperSlide',VueperSlide);

        let vuepers = document.getElementsByClassName('asd_vueper');
        let VueSlideshow = {};
        for (let i = 0; i < vuepers.length; i++) {
            let vueper = vuepers[i];
            let id = vueper.getAttribute('data-id');
            if ( window.hasOwnProperty('vueperSlideData') && window.vueperSlideData.hasOwnProperty(id)) {

                let app = Vue.extend(AsdSlideshowVue);
                let instanced_app = new app({
                    propsData: window.vueperSlideData[id]
                }).$mount(vueper.firstElementChild);

                /*
                VueSlideshow[id] = new Vue({
                    el: vueper,
                    components: {
                        'asd-slideshow': AsdSlideshowVue,
                    },
                    data: () => (window.vueperSlideData[id])

                });
                 */
            } else {
                console.log('No data to init', vueper);
            }
        }
    }

}
