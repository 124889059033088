export default class AsdWaves {

    constructor( options = {} ) {

        this.waves = [];

        this.doc = window.document;

        this.container = this.doc.querySelector('.asd_back');
        if (!this.container) {
            console.log("Cannot start AsdWave: no container");
            return;
        }

        this.mapSection();

        this.initWaves();

        this.orientationEvent = ( "onorientationchange" in window ) ? "orientationchange" : "resize";

        window.addEventListener(this.orientationEvent, () => {
            if (window.Asd.Waves) {
                window.Asd.Waves.adjustWaves();
            }
        });
    }

    mapSection() {
        let sections = this.doc.querySelectorAll('.waves-container');

        for (let i=0; i<sections.length; i++ ) {

            let wave = {};

            wave.section = sections[i];
            wave.top = this.calcTop(sections[i]);

            let div = this.doc.createElement('div');

            if (sections[i].classList.contains('no-wave')) {
                wave.type = 'square';
                div.classList.add('nwa');
            } else {
                wave.type = 'wave';
                div.classList.add('wa-'+(i+1));
            }

            wave.adder = this.calcAdder(wave.type);

            wave.last = (i + 1) >= sections.length;

            wave.div = div;

            this.container.appendChild(wave.div);

            this.waves.push(wave);
        }
    }

    initWaves() {
        for (let i=0; i < this.waves.length; i++) {

            let wave = this.waves[i];

            wave.div.classList.add('wa');

            wave.div.style.top = (wave.top - wave.adder) +'px';

            if (i+1 < this.waves.length) {
                this.calcHeight(wave, this.waves[i+1]);
            } else {
                this.calcHeight(wave);
            }
        }
    }



    adjustWaves() {

        for (let j=0; j < this.waves.length; j++) {
            let wave = this.waves[j];

            wave.top = this.calcTop(wave.section);
            wave.adder = this.calcAdder(wave.type);
        }

        for (let i=0; i < this.waves.length; i++) {

            let wave = this.waves[i];

            wave.div.style.top = (wave.top - wave.adder) +'px';

            if (i+1 < this.waves.length) {
                this.calcHeight(wave, this.waves[i+1]);
            } else {
                this.calcHeight(wave);
            }
        }
    }

    calcAdder(type) {
        switch(type) {
            case 'wave':
                return 350 * window.innerWidth / 2560;
            default:
                return 0;
        }
    }

    /**
     * @param section Element
     * @returns {number}
     */
    calcTop(section) {
        return section.getBoundingClientRect().top + window.scrollY;
    }

    calcHeight(wave, next) {
        let h;
        if (wave.last) {
            wave.div.classList.add('last');
            h = document.body.clientHeight - (wave.section.getBoundingClientRect().top + window.scrollY) + 500;
        } else if (next) {
            if (next.type === 'square') {
                h = next.top - (wave.top - wave.adder) + 50;
            } else {
                h = (next.top - next.adder) - wave.top + 3 * next.adder;
            }
        } else {
            console.error('Waves does not have a next, and is not last');
        }
        wave.div.style.height = h+'px';
    }
}
