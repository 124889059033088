import normalize from 'normalize.css/normalize.css';

import less from './less/template.less';

import slideout_css from 'slideout/index.css'

import scss from './scss/template.scss';



import createAsd from "./js";
export default createAsd();
